<template>
    <div role="status" class="animate-pulse w-[100%] h-[100px]">
        <div class="w-full h-full bg-white rounded-md dark:bg-gray-700"></div>
    </div>
</template>

<script>
export default {
    setup() {
        return {}
    }
}
</script>

<style lang="scss" scoped></style>