
<template>
  <div>
    <div class="modal-container">
      <div class="modal" @click.stop>
        <div class="md:px-10 px-5 py-5 flex flex-col">
          <button @click="closeModal" class="close-btn self-end">
            <vue-feather type="x" size="23"></vue-feather>
          </button>
          <div class="modal-title">{{ title }}</div>
        </div>
        <div class="modal-content" :class="customClassContent">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    customClassContent: {
      type: String,
      default: "",
    }
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
      if(document.body.hasAttribute('style')) {
        document.body.removeAttribute('style');
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
