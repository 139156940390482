<template>
  <Modalview @close-modal="closeModal" title="Update store">
    <div class="updatestore-form">
        <Form
          @submit="onSubmit"
          :validation-schema="updateStoreSchema()"
          class="updatestore-form__content"
        >
          <hs-input
            name="businessname"
            type="name"
            label="Business name"
            placeholder="Your business name"
            :required="false"
          />
          <hs-input
            name="businesstype"
            type="name"
            label="Business type"
            placeholder="Your business type"
            :required="false"
          />
          <hs-input
            name="tinnumber"
            type="name"
            label="TIN (Optional)"
            placeholder="Your TIN number"
            :required="false"
          />
          <hs-input
            name="phonenumber"
            type="name"
            label="Phone number"
            placeholder="Your phone number"
            :required="false"
          />
          <hs-input
            name="location"
            type="name"
            label="Location"
            placeholder="Your location"
            :required="false"
          />
          <hs-button type="update" label="UPDATE" class="mt-5" />
        </Form>
      </div>
  </Modalview>
</template>

<script>
import { Form } from "vee-validate";
import { updateStoreSchema } from "@/validations/updateStoreSchema";
export default {
  name: "UpdateStore",
  components: {Form},
  methods: {
    onSubmit() {
    },
    updateStoreSchema() {
      return updateStoreSchema;
    },
  closeModal() {
    this.$emit("close-modal");
  },
},
};
</script>

<style></style>
