<template>
  <div class="bg-white dark:bg-slate-800 h-full sm:pb-8">
    <div class="flex items-center sm:flex-col sm:gap-4 justify-between mb-6 border-b-[1px] dark:border-gray-700 px-6 sm:px-3 py-3">
      <div class="w-[55%] sm:w-full">
        <span class="font-semibold text-[17px] dark:text-gray-200">{{ title }}</span>
      </div>
      <div class="flex gap-4 sm:w-full sm:justify-between items-center">
        <hs-select :value="intervalValue" v-model="selectedInterval"
          custom-class="bg-white text-black border-[1px] border-gray-200" :options="getIntervals" placeholder="Interval"
          @value-change="handleSelectedInterval" />

        <hs-select :isLoading="loadingOptions" :value="value" v-model="selectedType"
          custom-class="bg-white text-black border-[1px] border-gray-200" :options="dashboardTypes"
          placeholder="Dashboard type" @value-change="handleSelectedType" />
      </div>
    </div>
    <div class="h-[70%] sm:h-[60%]">
      <line-chart thousands="," :data="getChartData" :download="title" :xtitle="xtitle" :ytitle="ytitle" class="px-6"
        style="height: 100%;" :colors="colors" />
    </div>
  </div>
</template>

<script>
import { capitalizeText } from '@/helper/utils'

export default {
  props: {
    data: {
      type: Object,
    },
    xtitle: {
      type: String,
      default: '',
    },
    ytitle: {
      type: String,
      default: '',
    },
    download: {
      type: String,
      default: 'chart',
    },
    title: {
      type: String,
      default: '',
    },
    dashboardTypes: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: String,
      default: '',
    },
    loadingOptions: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    value: [String, Array],
    intervalValue: [String, Array],
  },
  data() {
    return {
      intervals: ['hourly', 'daily', 'monthly'],
    }
  },
  computed: {
    selectedType: {
      get() {
        return this.dashboardTypes[0]
      },
      set(newValue) {
        this.$emit('update:selected-type', newValue)
      }
    },
    selectedInterval: {
      get() {
        return this.intervals[1]
      },
      set(newValue) {
        this.$emit('update:selected-interval', newValue)
      }
    },
    getChartData() {
      return this.chartData.map((item) => {
        return {
          ...item,
          name: capitalizeText(item.name),
        }
      })
    },
    getIntervals() {
      return this.intervals.map((item) => {
        return {
          name: capitalizeText(item),
          value: item,
        }
      })
    },
  },
  methods: {
    handleSelectedType(newValue) {
      this.$emit('update:selected-type', newValue)
    },
    handleSelectedInterval(newValue) {
      this.$emit('update:selected-interval', newValue)
    },
  }
};
</script>

<style scoped>
/* Add your Tailwind CSS classes here */
</style>
