import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import dashboard from "./dashboard";
import userDashboard from "./userDashboard";

export default createStore({
  modules: {
    auth,
    dashboard,
    userDashboard
  },
  plugins: [createPersistedState()],
});
