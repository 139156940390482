<template>
    <div class="date-filter__container" v-if="isOpen">
        <button class="date-filter__close" @click="close">
            <vue-feather type="x" size="16" />
        </button>

        <div class="md:w-[70%] mx-auto w-full">
            <div class="my-5 font-bold text-xl md:text-center uppercase dark:text-gray-100">Filter by date</div>

            <div class="date-filter__content">
                <div class="button-chips">
                    <button class="button-chips__item" v-for="interval in getDateRanges" :key="interval"
                        :class="{ 'button-chips__item--active': interval.time === intervalValue }"
                        @click="updateInterval(interval.dateRange)">
                        {{ interval.time }}
                    </button>
                </div>
                <div class="w-full">
                    <VDatePicker color="indigo" expanded v-model.range="dateRange" :columns="columns" mode="dateTime"
                        isrange is24hr :time-accuracy="3" :is-dark="isDarkMode" class="">
                    </VDatePicker>
                    <hs-button label="Filter" @click="filter" class="mt-5" color="black" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useScreens } from 'vue-screen-utils';
export default {
    props: {
        isOpen: Boolean,
        isDark: Boolean,
    },
    setup() {
        const { mapCurrent } = useScreens({ xs: '0px', sm: '640px', md: '768px', lg: '1024px' });
        const columns = mapCurrent({ lg: 2 }, 1);
        return {
            columns,
        };
    },
    data() {
        return {
            dateRange: {
                start: new Date(new Date() - new Date(30 * 24 * 60 * 60 * 1000)),
                end: new Date(),
            },
            intervals: ['current year', 'current month', 'current week', 'last year', 'last month', 'last week'],
            timePhrases: [
                'current year', 'current month', 'current week',
                'last year', 'last month', 'last week'
            ]
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        filter() {
            this.$emit('filter', this.dateRange);
            document.body.removeAttribute('style');
        },
        updateInterval(dateRange) {
            this.$emit('filter', dateRange);
            document.body.removeAttribute('style');
        }
    },
    computed: {
        isDarkMode() {
            return this.isDark;
        },
        getDateRanges() {
            const dateRanges = {
                'current year': () => ({
                    time: 'current year',
                    dateRange: { start: new Date(new Date().getFullYear(), 0, 1), end: new Date() }
                }),
                'current month': () => ({
                    time: 'current month',
                    dateRange: { start: new Date(new Date().getFullYear(), new Date().getMonth(), 1), end: new Date() }
                }),
                'current week': () => {
                    const currentDate = new Date();
                    const startOfWeek = new Date(currentDate - (currentDate.getDay() * 24 * 60 * 60 * 1000));
                    return {
                        time: 'current week',
                        dateRange: { start: startOfWeek, end: currentDate }
                    };
                },
                'last year': () => ({
                    time: 'last year',
                    dateRange: {
                        start: new Date(new Date().getFullYear() - 1, 0, 1),
                        end: new Date(new Date().getFullYear() - 1, 11, 31)
                    }
                }),
                'last month': () => {
                    const lastMonthDate = new Date();
                    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
                    return {
                        time: 'last month',
                        dateRange: {
                            start: new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth(), 1),
                            end: new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth() + 1, 0)
                        }
                    };
                },
                'last week': () => {
                    const currentDate = new Date();
                    const lastWeekStart = new Date(currentDate - (7 * 24 * 60 * 60 * 1000));
                    return {
                        time: 'last week',
                        dateRange: { start: lastWeekStart, end: currentDate }
                    };
                },
            };

            return this.timePhrases.map(phrase => dateRanges[phrase]()).filter(Boolean);
        }
    },
};
</script>

<style lang="scss">
.date {
    &-filter {
        &__container {
            @apply absolute sm:fixed top-0 left-0 px-8 w-full h-full md:h-[calc(100vh-150px)] overflow-auto dark:bg-slate-800 bg-gray-50 z-10;
            animation: slideInTop 1s forwards;
        }

        &__close {
            @apply absolute top-4 sm:top-6 right-8 w-7 h-7 flex justify-center items-center cursor-pointer border-2 border-transparent border-gray-900 dark:border-gray-300 text-gray-900 dark:text-gray-300 rounded-full;
        }

        &__content {
            @apply flex flex-col gap-6 items-start justify-center sm:mt-10;

            .button-chips {
                @apply flex w-full flex-wrap sm:gap-2 items-start justify-between;

                &__item {
                    @apply px-4 py-2 mt-1 w-[160px] sm:w-[152px] rounded-md uppercase text-sm sm:text-[13px] font-semibold border-2 border-gray-800 dark:border-gray-300 dark:text-gray-300 text-gray-800 hover:bg-gray-800 hover:text-gray-50 hover:dark:bg-gray-300 hover:dark:text-gray-800 cursor-pointer;
                }
            }
        }
    }
}

.date-filter__container.closing {
    animation: slideOutBottom 1s forwards;
}

@keyframes slideOutBottom {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(100%);
    }
}

@keyframes slideInTop {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.vc-time-picker.vc-attached {
    width: 50%;

    &:last-child {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
</style>
