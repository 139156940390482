<template>
  <div class="phonelogin-container">
    <div class="phonelogin-container__sub-logo">
      <div class="phonelogin-container__sub-logo--dot"></div>
      hovastore
    </div>
    <div class="phonelogin-form">
      <LoginSkeleton v-if="loadingUserInfo"/>
      <div class="phonelogin-form__container" v-else>
        <div class="phonelogin-form__container-header">
          <div class="phonelogin-form__container-header__title">
            Welcome back
          </div>
          <div class="phonelogin-form__container-header__sub-title">
            Continue with your phone number
          </div>
        </div>
        <div class="form-container">
          <div class="h-[110px]">
            <div class="phonelogin-form__container-phone__title">
              Phone number
            </div>
            <vue-tel-input class="phonelogin-form__container-phone__input" :class="isValid && phone.length > 4
              ? 'focus-within:border-[#581845] border-[#581845]'
              : 'focus-within:border-red-600'
              " v-model="phone" :dynamicPlaceholder="true" :inputOptions="{ showDialCode: true }"
              :validCharactersOnly="true" :mode="international" @keyup.enter="isValid ? requestSMSVerify() : ''"
              v-bind="bindProps" @validate="handlePhoneInput" :disabled="showOTPCode"></vue-tel-input>
            <p v-show="!isValid && phone.length > 4" class="text-red-600 text-[12px] mt-1">
              Phone number should be valid
            </p>
          </div>
          <div v-if="showOTPCode">
            <div class="phonelogin-form__container-phone__link">
              Wrong phone number?
              <a class="text-primary dark:text-gray-300 underline cursor-pointer font-normal text-base" @click="hideOTPCode">
                Change it</a>
            </div>
            <div class="phonelogin-form__container-otp__title">
              OTP Code (sent on your phone number)
            </div>
            <div class="w-full flex items-center justify-between mt-3 mb-5 otp-container">
              <v-otp-input ref="otpInput" input-classes="otp-input" separator=" " :num-inputs="6"
                :should-auto-focus="true" input-type="letter-numeric" @on-complete="handleOnComplete" />
            </div>

            <div v-if="!resend">
              <div class="phonelogin-form__container-otp__link" v-if="countdownTime > 0">
                Resend code in
                <span class="text-primary dark:text-gray-300">{{
                  formattedCountdown
                }}</span>
              </div>

              <div class="phonelogin-form__container-otp__link" v-else>
                Didn't receive code?
                <a class="text-primary dark:text-gray-300 underline cursor-pointer font-normal text-base" role="button"
                  @click="requestSMSVerify(true)">
                  Resend it</a>
              </div>
            </div>
          </div>

          <hs-button :class="showOTPCode ? 'mt-5' : ''" label="continue" @click="requestSMSVerify" :isLoading="isLoading"
            :isDisabled="!isValid || showOTPCode" />
        </div>
      </div>
    </div>
    <div class="phonelogin-logo">
      <div class="phonelogin-logo__image">
        <img src="@/assets/images/logo.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import VOtpInput from "vue3-otp-input";
import { toast } from "vue3-toastify";
import LoginSkeleton from "@/skeletons/LoginSkeleton"

export default {
  name: "PhoneLoginView",
  components: {
    VueTelInput,
    VOtpInput,
    LoginSkeleton
  },
  data() {
    return {
      phone: "",
      showOTPCode: false,
      requestData: {},
      bindProps: {
        placeholder: "Enter your phone number",
        defaultCountry: "RW",
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: { showSearchBox: true, showFlags: true },
        inputOptions: { showDialCode: true },
      },
      isValid: false,
      phoneNumber: "",
      totalSeconds: 60, // Change this to the desired total seconds
      countdownTime: 0,
      interval: null,
      resend: false,
      isLoading: false
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    },
    loadingUserInfo() {
      return this.$store.getters.isLoadingUser;
    },
    formattedCountdown() {
      const hours = Math.floor(this.countdownTime / 3600);
      const minutes = Math.floor((this.countdownTime % 3600) / 60);
      const remainingSeconds = this.countdownTime % 60;

      const formattedHours = this.formatTime(hours);
      const formattedMinutes = this.formatTime(minutes);
      const formattedSeconds = this.formatTime(remainingSeconds);

      if (hours > 0) {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      } else {
        return `${formattedMinutes}:${formattedSeconds}`;
      }
    },
  },
  methods: {
    handlePhoneInput(value) {
      this.isValid = value.valid;
      this.phoneNumber = value.number;
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },
    startCountdown() {
      this.countdownTime = this.totalSeconds;

      this.interval = setInterval(() => {
        if (this.countdownTime <= 0) {
          clearInterval(this.interval);
        } else {
          this.countdownTime--;
        }
      }, 1000);
    },
    requestSMSVerify(hasResend = false) {
      this.isLoading = true
      const payload = { phone_number: this.phoneNumber };
      if (hasResend) this.resend = true
      this.$store
        .dispatch("requestSMS", payload)
        .then((response) => {
          this.isLoading = false
          this.requestData = response;
          this.showOTPCode = true;
          this.startCountdown();
          toast.success("OTP sent on your phone number");
          if (hasResend) this.resend = false
        })
        .catch(() => {
          this.isLoading = false
          if (hasResend) this.resend = false
          toast.error(this.$store.getters.getError);
        });
    },
    hideOTPCode() {
      this.showOTPCode = false;
    },
    handleOnComplete(value) {
      this.isLoading = true
      const payload = {
        ...this.requestData,
        sms_code: `${value}`,
      };

      this.$store.dispatch("verifyOTP", payload).then(() => {
        this.$store.dispatch("getUserInfo").then(() => {
          this.$router.push("/store/dashboard");
          this.isLoading = false
          // if (response?.data?.admin_permissions.includes("SUPER_ADMIN") || response.includes("AUDITOR_ADMIN")) {
          //   this.$router.push("/admin/dashboard");
          // } else {
          //   this.$router.push("/store/dashboard");
          // }
        }).catch(() => {
          this.isLoading = false
          this.$router.push("/");
        })
      }).catch(() => {
        this.isLoading = false
        toast.error(this.$store.getters.getError);
      })
    },
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  mounted() {
    document.title = "Login | HovaStore";
    const user = this.$store.getters.getAuthenticatedUser
    if(user) {
      this.$router.push("/store/dashboard");
    }
  },
};
</script>

<style lang="scss">
.otp-container {
  div {
    gap: 5px;

    div {
      width: 100%;
    }
  }
}

.otp-input {
  width: 100%;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
</style>
