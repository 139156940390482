<template>
  <div class="bg-gray-100 dark:bg-slate-700 h-full w-full">
    <div class="store-header">
      <div class="store-header-menu">
        <div class="header-menu">
          <button class="header-menu__item active">Dashboard</button>
          <!-- <button class="header-menu__item">My Store</button> -->
        </div>
        <div class="header-filter">
          <hs-select v-if="selectedStore" :value="selectedStore" @value-change="handleSelectedStore"
            v-model="selectedStore" class="min-w-[200px]" custom-class="text-gray-800 border-gray-800"
            :options="getOptionsForStore(stores)" placeholder="Select store" :isLoading="isLoadingStores"></hs-select>
          <button class="btn-icon bg-primary" @click="handleDateFilter">
            <vue-feather type="calendar" class="text-white" size="16" />
          </button>
          <button class="btn-icon bg-info" @click="handleShareDashboardModal">
            <vue-feather type="share" class="text-white" size="16" />
          </button>
        </div>
      </div>
    </div>
    <div class="store-dashboard">
      <DateFilter :isDark="isDark" :isOpen="showDateFilter" @close="closeDateFilter" @filter="handleDateRangeFilter" />
      <div class="store-charts">
        <div class="store-charts__left">
          <div class="w-full h-full flex items-center justify-center px-6 py-4" v-if="isLoadingAmountCharts">
            <div class="w-8 h-8 rounded-full animate-spin border-2 border-solid border-info border-t-transparent"></div>
          </div>
          <hs-chart v-else :title="amountDynamicTitle" :dashboardTypes="getSelectOptions(dashboardTypesAmount)"
            :value="selectedAmountType" :intervalValue="selectedAmountInterval" v-model="selectedAmountType"
            @update:selected-type="handleSelectedAmountType" :loadingOptions="isLoadingStoreDashboardTypes"
            :chartData="amountChartData" @update:selected-interval="handleSelectedAmountInterval" />
        </div>
        <div class="store-charts__right">
          <div class="w-full h-full flex items-center justify-center px-6 py-4" v-if="isLoadingCountCharts">
            <div class="w-8 h-8 rounded-full animate-spin border-2 border-solid border-info border-t-transparent"></div>
          </div>
          <hs-chart v-else :title="countDynamicTitle" :colors="['#1D8348']"
            :dashboardTypes="getSelectOptions(dashboardTypesCount)" :value="selectedCountType"
            :intervalValue="selectedCountInterval" v-model="selectedCountType"
            @update:selected-type="handleSelectedCountType" :loadingOptions="isLoadingStoreDashboardTypes"
            :chartData="countChartData" @update:selected-interval="handleSelectedCountInterval" />
        </div>
      </div>
      <div class="store-statscards">
        <div v-for="item in statsCardItems" :key="item.iconType" class="flex flex-1">
          <StatsSkeleton v-if="loadingStats" />
          <Statscard class="border dark:border-transparent" v-else :iconType="item.iconType" :iconColor="item.iconColor"
            :statsNumber="item.statsNumber" :statsDescription="item.statsDescription"
            :cardIconClass="item.cardIconClass" />
        </div>
      </div>
    </div>
    <div>
      <UpdateStore v-if="showModal" @close-modal="handleModalClose" />
      <UpdateProfile v-if="showProfileModal" @close-modal="handleProfileModalClose" />
      <ShareDashboard v-if="showShareDashboardModal" :isDark="isDark" @close-modal="handleShareDashboardModalClose" />
    </div>
  </div>
</template>

<script>
import { useToggle, useDark } from "@vueuse/core";
import StatsSkeleton from "@/skeletons/StatsSkeleton";
import { format } from "date-fns";
import { toast } from "vue3-toastify";
import UpdateStore from "@/components/modals/store/UpdateStore";
import UpdateProfile from "@/components/modals/profile/UpdateProfile.vue";
import ShareDashboard from "@/components/modals/store/ShareDashboard";

export default {
  name: "DashboardView",
  components: {
    StatsSkeleton,
    UpdateStore,
    ShareDashboard,
    UpdateProfile,
  },

  setup() {
    const isDark = useDark();
    const toggleTheme = useToggle(isDark);
    const toggle = () => {
      useToggle(isDark);
      toggleTheme();
    };

    return {
      isDark: isDark,
      toggleTheme,
      toggle,
    };
  },

  data() {
    return {
      statsCardItems: [
        {
          key: "sales_profit",
          iconType: "users",
          iconColor: "text-emerald-500",
          statsNumber: "...",
          statsDescription: "Sales Profit",
          cardIconClass: "bg-cyan-700 bg-opacity-10",
        },
        {
          key: "total_purchases",
          iconType: "shopping-bag",
          iconColor: "text-sky-600",
          statsNumber: "...",
          statsDescription: "Total Purchases",
          cardIconClass: "bg-sky-600 bg-opacity-10",
        },
        {
          key: "total_purchases_sold",
          iconType: "gift",
          iconColor: "text-red-400",
          statsNumber: "...",
          statsDescription: "Total Purchases Sold",
          cardIconClass: "bg-red-400 bg-opacity-10",
        },
        {
          key: "total_sales",
          iconType: "life-buoy",
          iconColor: "text-primary",
          statsNumber: "...",
          statsDescription: "Total Sales",
          cardIconClass: "bg-primary bg-opacity-10",
        },
      ],
      showModal: false,
      startDate: new Date(new Date() - new Date(30 * 24 * 60 * 60 * 1000)),
      endDate: new Date(),
      dynamicTitle: "", //Transactions from 30 days ago to today
      stores: ["Hovastore"],
      selectedInterval: "daily",
      selectedStore: null,
      dashboardTypes: ["sales_amount", "sales_count", "purchase_amount"],
      selectedType: "",
      dashboardTypesCount: [],
      dashboardTypesAmount: [],
      selectedCountType: "",
      selectedAmountType: "",
      amountChartData: [],
      countChartData: [],
      isLoadingCharts: false,
      isLoadingAmountCharts: false,
      isLoadingCountCharts: false,
      selectedAmountInterval: "daily",
      selectedCountInterval: "daily",
      countDynamicTitle: "",
      amountDynamicTitle: "",
      showDateFilter: false,
      showProfileModal: false,
      showShareDashboardModal: false,
    };
  },
  computed: {
    loadingStats() {
      return this.$store.getters.isLoadingStats;
    },
    isLoadingStores() {
      return this.$store.getters.isLoadingStores;
    },
    isLoadingStoreDashboardTypes() {
      return this.$store.getters.isLoadingStoreDashboardTypes;
    },
    isLoadingStoreTransactionCountData() {
      return this.$store.getters.isLoadingStoreTransactionCountData;
    },
  },
  methods: {
    handleShowProfileModal() {
      this.showProfileModal = true;
      document.body.style.overflow = "hidden";
    },
    handleShareDashboardModal() {
      this.showShareDashboardModal = true;
      document.body.style.overflow = "hidden";
    },
    handleProfileModalClose() {
      const modal = document.querySelector(".modal");
      modal.classList.add("closing");
      setTimeout(() => {
        modal.classList.remove("closing");
        this.showProfileModal = false;
      }, 1000);
    },
    handleShareDashboardModalClose() {
      const modal = document.querySelector(".modal");
      modal.classList.add("closing");
      setTimeout(() => {
        modal.classList.remove("closing");
        this.showShareDashboardModal = false;
      }, 1000);
    },
    closeDateFilter() {
      //remove overflow on body
      document.body.style.overflow = "hidden";
      const modal = document.querySelector(".date-filter__container");
      modal.classList.add("closing");
      setTimeout(() => {
        modal.classList.remove("closing");
        this.showDateFilter = false;
      }, 1000);
    },
    handleDateFilter() {
      this.showDateFilter = true;
      //remove overflow on body
      document.body.style.overflow = "hidden";
    },
    handleDateRangeFilter(dateRange) {
      this.startDate = dateRange.start;
      this.endDate = dateRange.end;
      this.generateDynamicTitle();
      this.closeDateFilter();
      this.getSelectedStoreStats()
      this.handleStoreSelected(this.selectedAmountType);
      this.handleStoreSelected(this.selectedCountType);
    },
    handleSelectedCountInterval(interval) {
      this.selectedCountInterval = interval;
      this.handleStoreSelected(this.selectedCountType, false, true);
    },
    handleSelectedAmountInterval(interval) {
      this.selectedAmountInterval = interval;
      this.handleStoreSelected(this.selectedAmountType, true, false);
    },
    handleSelectedStore(newStore) {
      this.selectedStore = newStore;
      this.$store.commit("SET_SELECTED_STORE", newStore);
      this.getSelectedStoreStats()
      this.handleStoreSelected(this.selectedAmountType);
      this.handleStoreSelected(this.selectedCountType);
    },
    handleSelectedAmountType(type) {
      this.selectedAmountType = type;
      this.handleStoreSelected(this.selectedAmountType, true, false);
    },
    handleSelectedCountType(type) {
      this.selectedCountType = type;
      this.handleStoreSelected(this.selectedCountType, false, true);
    },
    capitalizeText(text) {
      return text
        ?.toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    generateDynamicTitle(isNow = false) {
      if (isNow) {
        this.countDynamicTitle = `${this.capitalizeText(this.selectedCountType)} from the last 30 days`
        this.amountDynamicTitle = `${this.capitalizeText(this.selectedAmountType)} from the last 30 days`
      } else {
        this.countDynamicTitle = `${this.capitalizeText(this.selectedCountType)} from ${this.$formatDateTime(this.startDate)} to ${this.$formatDateTime(this.endDate)}`;
        this.amountDynamicTitle = `${this.capitalizeText(this.selectedAmountType)} from ${this.$formatDateTime(this.startDate)} to ${this.$formatDateTime(this.endDate)}`;
      }
    },
    getOptionsForStore(data) {
      return data.map((item) => ({
        value: item.id,
        name: item.name,
      }));
    },
    getSelectOptions(data) {
      return data.map((item) => ({
        value: item,
        name: this.capitalizeText(item),
      }));
    },
    getDateRangeFormatted() {
      return {
        start: format(this.startDate, "dd/MM/yyyy HH:mm:ss"),
        end: format(this.endDate, "dd/MM/yyyy HH:mm:ss"),
      };
    },
    formatNumbers(number) {
      if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + "M";
      } else if (number >= 1e3) {
        return (number / 1e3).toFixed(1) + "K";
      } else {
        return number;
      }
    },
    generateCardItems(response) {
      const icons = ["📊", "💰", "💸", "🛒", "💵"];
      const textColors = ["text-red-400", "text-blue-400", "text-yellow-400", "text-indigo-400", "text-pink-400"]; // Text color options
      const bgClasses = ["bg-red-400 bg-opacity-10", "bg-blue-400 bg-opacity-10", "bg-yellow-400 bg-opacity-10", "bg-indigo-400 bg-opacity-10", "bg-pink-400 bg-opacity-10"]; // Background class options

      const keys = Object.keys(response);
      const objects = keys.map((key, index) => ({
        key: key,
        iconType: icons[index % icons.length],
        iconColor: textColors[index % textColors.length],
        statsNumber: this.formatNumbers(response[key]),
        statsDescription: this.$capitalizeText(key),
        cardIconClass: bgClasses[index % bgClasses.length],
      }));

      return objects;
    },
    handleModalClose() {
      const modal = document.querySelector(".modal");
      modal.classList.add("closing");
      setTimeout(() => {
        modal.classList.remove("closing");
        this.showModal = false;
      }, 1000);
    },
    toggleProfileModal() {
      this.showProfileModal = !this.showProfileModal;
    },

    handleUpdateStartDate(newStartDate) {
      this.startDate = newStartDate;
    },
    handleUpdateEndDate(newEndDate) {
      this.endDate = newEndDate;
    },
    capitalizeWords() {
      return this.selectedType
        ?.toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    getFilterData(selectedDashboardType = "") {
      const { start, end } = this.getDateRangeFormatted();
      const intervalParam = selectedDashboardType?.toLowerCase()?.includes("amount")
        ? this.selectedAmountInterval.toLowerCase()
        : this.selectedCountInterval.toLowerCase();
      return {
        start_time: start,
        end_time: end,
        interval: intervalParam,
        dashboard_type: selectedDashboardType,
        selectedStore: this.selectedStore,
      };
    },
    async getSelectedStoreStats() {
      try {
        const filterData = this.getFilterData();
        const statsRes = await this.$store.dispatch(
          "getStoreStats",
          filterData
        );
        
        delete statsRes?.start_time;
        delete statsRes?.end_time;

        this.statsCardItems = this.generateCardItems(statsRes)

      } catch (error) {
        toast.error(
          error?.response?.data?.error?.message || "An error occurred when fetching store statistics"
        );
      }
    },
    async handleStoreSelected(selectedType, loadAmount = false, loadCount = false) {
      try {
        this.isLoadingAmountCharts = loadCount ? false : true;
        this.isLoadingCountCharts = loadAmount ? false : true;
        const filterData = this.getFilterData(selectedType);

        const responseData = await this.$store.dispatch(
          "getStoreTransactionStats",
          filterData
        );
        this.isLoadingAmountCharts = false;
        this.isLoadingCountCharts = false;

        if (selectedType.toLowerCase().includes("amount")) {
          this.amountChartData = [responseData];
        } else if (selectedType.toLowerCase().includes("count")) {
          this.countChartData = [responseData];
        }

      } catch (error) {
        this.isLoadingAmountCharts = false;
        this.isLoadingCountCharts = false;
        toast.error(
          error?.response?.data?.error?.message || "An error occurred when fetching store data"
        );
      }
    },
    getDashboardTypes() {
      this.$store
        .dispatch("getStoreDashboardTypes")
        .then((response) => {
          this.dashboardTypes = response || [];
          this.dashboardTypesAmount = this.dashboardTypes.filter((item) =>
            item.toLowerCase().includes("amount")
          );
          this.dashboardTypesCount = this.dashboardTypes.filter((item) =>
            item.toLowerCase().includes("count")
          );
          this.selectedAmountType = this.dashboardTypesAmount[0];
          this.selectedCountType = this.dashboardTypesCount[0];
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error?.message ||
            "An error occurred when fetching dashboard types"
          );
        });
    },
  },
  mounted() {
    this.getDashboardTypes();
    this.$store
      .dispatch("getAllUsersStore")
      .then((response) => {
        this.stores = response?.owner_of;
        if (this.stores.length > 0 && this.dashboardTypes.length > 0) {
          this.selectedStore = this.stores[0].id;
          this.$store.commit("SET_SELECTED_STORE", this.stores[0].id);
          this.generateDynamicTitle(true);
          this.getSelectedStoreStats()
          this.handleStoreSelected(this.selectedAmountType);
          this.handleStoreSelected(this.selectedCountType);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error?.message || "An error occurred when fetching stores"
        );
      });
  },
};
</script>
