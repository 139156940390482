import axios from "axios";

const http = axios.create({
  withCredentials: true,
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "https://staging-api.hova.ai/api/v1"
      : "https://staging-api.hova.ai/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
