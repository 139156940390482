<template>
    <div>
        <label :for="id" class="block text-sm font-medium text-gray-700" v-if="label">
            {{ label }}
        </label>
        <div class="">
            <div role="status" class="animate-pulse" v-if="isLoading">
                <div class="h-8 w-full bg-gray-200 rounded-md dark:bg-gray-700"></div>
            </div>
            <select v-else id="small"
                class="w-full p-2 text-sm border outline-none border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
                :class="customClass" :multiple="multiple" :disabled="disabled" :required="required" :value="selectedValue"
                :name="name" :searchable="searchable" :clearable="clearable" v-model="selectedValue">
                <option selected disabled>
                    {{ placeholder }}
                </option>
                <option class="hover:bg-gray-500 hover:text-white" v-for="option in options" :key="option.value"
                    :value="option.value">
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "HSSelect",
    props: {
        options: Array,
        placeholder: String,
        name: String,
        selected: String,
        id: String,
        label: String,
        error: String,
        disabled: Boolean,
        required: Boolean,
        multiple: Boolean,
        searchable: Boolean,
        clearable: Boolean,
        customClass: String,
        isLoading: {
            type: Boolean,
            default: false,
        },
        value: [String, Array],
    },
    computed: {
        selectedValue: {
            get() {
                return (
                    this.value ||
                    (this.options.length > 0 ? this.options[0].value : "") ||
                    ""
                );
            },
            set(newValue) {
                this.$emit("value-change", newValue);
            },
        },
    },
    // methods: {
    //     handleInput(event) {
    //         this.$emit("value-change", event.target.value);
    //     },
    // },
};
</script>

<style lang="scss" scoped></style>
