<template>
  <Modalview @close-modal="closeModal" customClassContent="sm:px-5">
    <div class="updateprofile-form">
      <div class="profile-container">
        <div class="profile-container__header">
          <div class="profile-container__image">
            {{ user?.names?.charAt(0) }}
          </div>
          <div class="">
            <div class="profile-container__name">{{ user?.names }}</div>
            <div class="profile-container__phone">{{ user?.phone_number }}</div>
          </div>
        </div>
        <hs-button label="Logout" @click="logout" />
      </div>
      <!-- <div class="updateprofile-form__title">Update profile</div> -->
      <Form @submit="onSubmit" :validation-schema="updateProfileSchema()" class="updateprofile-form__content hidden">
        <hs-input name="name" type="name" label="Full Name" placeholder="Your full name" :required="false" />
        <hs-input name="gender" type="gender" label="Gender" placeholder="Your gender" :required="false" />
        <hs-button type="update" label="UPDATE" class="mt-5" />
      </Form>
    </div>
  </Modalview>
</template>

<script>
import { Form } from "vee-validate";
import { updateProfileSchema } from "@/validations/updateProfileSchema";
export default {
  name: "UpdateProfile",
  components: { Form },
  props: {
    showProfileModal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.getAuthenticatedUser;
    },
  },
  methods: {
    onSubmit() {
    },
    updateProfileSchema() {
      return updateProfileSchema;
    },
    closeModal() {
      this.$emit("close-modal");
    },
    logout() {
      this.$router.push('/');
      this.$store.dispatch("logoutUser")
        .then(() => { })
        .catch(() => { });
    },
  },
};
</script>

<style></style>
