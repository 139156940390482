import http from "@/helper/http";
import api from "@/helper/api";

export default {
  state: {
    loading: false,
    error: [],
    response: [],
    authenticatedUser: [],
    loadingUser: false,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_LOADING_USER(state, loadingUser) {
      state.loadingUser = loadingUser;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_RESPONSE(state, response) {
      state.response = response;
    },
    SET_AUTH_USER(state, authenticatedUser) {
      state.authenticatedUser = authenticatedUser;
    },
  },
  actions: {
    requestSMS({ commit }, payload) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        http
          .post(api.request_sms, payload)
          .then((response) => {
            commit("SET_RESPONSE", response?.data);
            commit("SET_LOADING", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit(
              "SET_ERROR",
              error.response?.data?.message ??
                "An error occurred while sending an SMS, Please try again"
            );
            commit("SET_LOADING", false);
            reject(error?.response);
          });
      });
    },
    verifyOTP({ commit }, payload) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        http
          .post(api.verify_code, payload)
          .then((response) => {
            commit("SET_RESPONSE", response?.data);
            commit("SET_LOADING", true);

            resolve(response?.data);
          })
          .catch((error) => {
            commit(
              "SET_ERROR",
              error.response?.data?.message ??
                "An error occurred while verifying the OTP, Please try again"
            );
            commit("SET_LOADING", false);
            reject(error?.response);
          });
      });
    },
    checkUserPermissions({ commit }) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        http
          .get(api.verify_admin, {
            headers: {},
          })
          .then((response) => {
            const permissions = response.data.permissions;
            commit("SET_LOADING", false);
            resolve(permissions);
          })
          .catch((error) => {
            commit("SET_LOADING", false);
            reject(error);
          });
      });
    },
    getUserInfo({ commit }) {
      commit("SET_LOADING_USER", true);
      return new Promise((resolve, reject) => {
        http
          .get(api.authenticated_user)
          .then((response) => {
            commit("SET_AUTH_USER", response?.data);
            commit("SET_LOADING_USER", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_LOADING_USER", false);
            reject(error);
          });
      });
    },
    logoutUser({ commit }) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        http
          .post(api.logout)
          .then((response) => {
            commit("SET_LOADING", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit(
              "SET_ERROR",
              error.response?.data?.message ??
                "Unexpected error occurred, Please try again"
            );
            commit("SET_LOADING", false);
            reject(error?.response);
          });
      });
    },
  },
  getters: {
    isLoading: (state) => state.loading,
    isLoadingUser: (state) => state.loadingUser,
    getError: (state) => state.error,
    getResponse: (state) => state.response,
    getAuthenticatedUser: (state) => state.authenticatedUser,
  },
};
