<template>
  <Modalview @close-modal="closeModal" title="Share dashboard">
    <div class="updatestore-form">
      <Form @submit="onSubmit" :validation-schema="updateStoreSchema()" class="updatestore-form__content">
        <div>
          <label for="dashboard-types-picker" class="input-label">Select dashboard types</label>
          <div class="space-y-3">
            <div v-for="(item, index) in dashboardTypes" :key="index" @click="toggleSelection(item)" :class="{
              'bg-blue-500 text-white': selectedItems.includes(item),
              'bg-gray-200': !selectedItems.includes(item)
            }" class="cursor-pointer inline-block mr-5 px-3 py-1 rounded-full text-sm font-semibold">
              {{ item.split('_').join(' ') }}
            </div>
          </div>
        </div>
        <div class="flex flex-col mt-3" v-if="!isLiveDashboard">
          <label for="date-range" class="my-2 input-label">Date Range</label>
          <VDatePicker class="date-range" :columns="columns" v-model.range="dateRange" mode="dateTime" isrange :is-dark="isDark"
            expanded />
        </div>
        <div class="my-4">
          <label for="live" class="input-label">Live dashboard is not bound by date range</label>
          <div class="mt-1 flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
            <input id="bordered-checkbox-1" v-model="isLiveDashboard" type="checkbox" value="" name="bordered-checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="bordered-checkbox-1"
              class="w-full py-3 ml-2 text-md font-medium text-gray-900 dark:text-gray-300">Live dashboard</label>
          </div>
        </div>
        <hs-input @input="handleInputChange" name="shareddashboardname" type="name" label="Shared Dashboard Name"
          placeholder="Link name" :required="true" />

        <div v-if="dashboardLink" class="copy-link">
          <label for="copy-link" class="input-label mr-5">Copy Link</label>
          <input class="copy-link w-full" type="text" name="copy-link" :value="dashboardLink" />
        </div>

        <hs-button :isLoading="isLoading" @click="onSubmit" type="button" label="Copy Share Link" class="mt-5" />
      </Form>
    </div>
  </Modalview>
</template>

<script>
import { Form } from "vee-validate";
import { updateStoreSchema } from "@/validations/updateStoreSchema";
import { toast } from "vue3-toastify";
import { VDatePicker } from "v-calendar";
import { format } from "date-fns";
import { getDashboardKey } from '@/helper/utils'
import { useScreens } from 'vue-screen-utils';

export default {
  name: "ShareDashboard",
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { mapCurrent } = useScreens({ xs: '0px', sm: '640px', md: '768px', lg: '1024px' });
    const columns = mapCurrent({ lg: 2 }, 1);
    return {
      columns,
    };
  },
  components: { Form, VDatePicker },
  data() {
    return {
      dashboardTypes: [],
      selectedDashboardTypes: [],
      isLiveDashboard: false,
      dateRange: {
        start: new Date().setDate(new Date().getDate() - 30),
        end: new Date(),
      },
      dashboardLink: null,
      sharedDashboardName: null,
      selectedItems: [],
      isLoading: false,
    };
  },
  watch: {
    isLiveDashboard: function () {
      this.dashboardLink = null;
    },
    selectedDashboardTypes: function () {
      this.dashboardLink = null;
    },
    dateRange: function () {
      this.dashboardLink = null;
    }
  },
  methods: {
    toggleSelection(index) {
      if (this.selectedItems.includes(index)) {
        // Item is already selected, so deselect it
        this.selectedItems = this.selectedItems.filter((i) => i !== index);
      } else {
        // Item is not selected, so select it
        this.selectedItems.push(index);
      }
    },
    handleInputChange(value) {
      // TODO: Use better the HSInput component. This is a hack, workaround.
      if (value?.target || !value) return;

      this.sharedDashboardName = value;
    },
    onSubmit() {
      let data = {
        name: this.sharedDashboardName,
        store_id: this.$store.getters.getSelectedStore,
        store_dashboard_types: this.selectedItems,
        is_live: this.isLiveDashboard,
        timeframe_start: this.isLiveDashboard ? null : this.getFormattedDate(this.dateRange.start),
        timeframe_end: this.isLiveDashboard ? null : this.getFormattedDate(this.dateRange.end),
      }
      const cachedLink = this.$store.getters.getLinkCache[getDashboardKey(data)];
      if (cachedLink) {
        this.copyLinkToClipboard(cachedLink);
        return;
      }
      this.isLoading = true;
      this.$store.dispatch("createDashboardShareLink", data).then(() => {
        this.dashboardLink = this.$store.getters.getDashboardShareLink;
        this.copyLinkToClipboard(this.dashboardLink);
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        toast.error(error?.response?.data?.error?.message || "Failed to share dashboard");
      })
    },
    getFormattedDate(date) {
      return format(date, "dd/MM/yyyy HH:mm:ss");
    },
    updateStoreSchema() {
      return updateStoreSchema;
    },
    closeModal() {
      this.$emit("close-modal");
    },
    getDashboardTypeName(name) {
      return name.replace(/_/g, " ").toLowerCase();
    },
    copyLinkToClipboard(link) {
      navigator.clipboard.writeText(link).then(() => {
        toast.success("Link copied");
      }).catch(() => {
        toast.error("Failed to copy link");
      })
    }
  },
  mounted() {
    this.$store.dispatch("getStoreDashboardTypes").then(() => {
      this.dashboardTypes = this.$store.getters.getStoreDashboardTypes;
    }).catch(() => {
      // toast.error("Failed to fetch dashboard types");
      //TODO: Remove this when the API is ready
      this.dashboardTypes = ['sales_count', 'purchases_amount'];
    })
  },

};
</script>

<style></style>
