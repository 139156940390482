<template>
  <div class="sidebar-container">
    <div class="logout-modal" v-if="loading">
      <div class="loading"></div>
    </div>
    <div class="sidebar-logo">
      <img src="../../assets/images/logoIcon.png" class="sidebar-logo__image" />
      <div class="sidebar-logo__title">HovaStore</div>
      <div class="sidebar-logo__subtitle">{{subtitle}}</div>
      <div class="sidebar-logo__close" @click="close">
        <vue-feather type="x" size="30" />
      </div>
    </div>
    <div class="sidebar-nav">
      <div class="sidebar-nav__title">
        <hr class="sidebar-nav__title--line" />
        <span class="sidebar-nav__title--text">general</span>
        <hr class="sidebar-nav__title--line" />
      </div>
      <div class="sidebar-nav__links">
        <div class="sidebar-nav__links--card" v-for="item in menus" :key="item.icon"
          :class="{ 'active-link': item.link === 'dashboard' }">
          <div class="sidebar-nav__links--card__item">
            <div class="sidebar-nav__links--card__icon">
              <vue-feather :type="item.icon" size="20" class=""></vue-feather>
            </div>
            <div class="sidebar-nav__links--card__title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-settings">
      <div class="sidebar-nav__title">
        <hr class="sidebar-nav__title--line" />
        <span class="sidebar-nav__title--text">settings</span>
        <hr class="sidebar-nav__title--line" />
      </div>

      <div class="sidebar-settings__user">
        <div class="sidebar-settings__user--icon">
          <vue-feather type="user" size="23"></vue-feather>
        </div>

        <div class="sidebar-settings__user--name">{{ abbreviateName(user?.names) }}</div>
        <div class="sidebar-settings__user--role">{{user?.phone_number }}</div>
        <div class="sidebar-settings__user--links">
          <button @click="toggleProfileModal()" class="setting-icon">
            <vue-feather type="settings" size="20" class="link-icon"></vue-feather>
          </button>

          <vue-feather type="power" size="20" class="link-icon" role="button" @click="logout"></vue-feather>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarComponent",
  props: ["open", "subtitle"],
  data() {
    return {
      menus: [
        { title: "Dashboard", icon: "home", link: "dashboard" },
        { title: "Users", icon: "users", link: "" },
        { title: "Stores", icon: "grid", link: " " },
      ],
      isActive: false,
      loading: false
    };
  },
  computed: {
    user() {
      return this.$store.getters.getAuthenticatedUser;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    toggleProfileModal() {
      this.$emit("toggle-profilemodal");
    },
    logout() {
      this.loading = true
      this.$store.dispatch("logoutUser").then(() => {
        this.loading = false
        this.$router.push('/');
      })
    },
    abbreviateName(fullName) {
      const names = fullName?.split(' ');

      if (names && names.length >= 2) {
        const firstNameInitial = names[0][0].toUpperCase();
        const lastName = names[names.length - 1];
        return `${firstNameInitial}. ${lastName}`;
      }

      return fullName;
    }
  },
  mounted() {
    this.$store.dispatch("getUserInfo")
  }
};
</script>

<style lang="scss" scoped>
.loading {
  width: 35px;
  height: 35px;
  margin-left: 10px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
