<template>
  <nav></nav>
  <router-view />
</template>

<script>
export default {
  mounted() {
    document.title = "HovaStore";
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>

