class Permissions {

  constructor(permissions) {
    this.permissions = permissions;
  }

  hasPermission(permission) {
    return this.permissions.includes(permission);
  }

  isSuperAdmin() {
    return this.hasPermission("SUPER_ADMIN");
  }

  isAuditor() {
    return this.hasPermission("AUDITOR");
  }

  isCashier() {
    return this.hasPermission("CASHIER");
  }
}

export default Permissions;
