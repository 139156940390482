<template>
    <div>User view</div>
  </template>
  
  <script>
  export default {
    name: "UsersView",
  };
  </script>
  <style lang="scss" scoped></style>
  
  