<template>
  <div class="statscard-container">
    <div class="statscard-details">
      <div class="statscard-details__icon" :class="cardIconClass">
        <div class="">{{iconType}}</div>
      </div>
      <div class="statscard-details__content">
        <div class="statscard-details__content--number">{{ statsNumber }}</div>
        <div class="statscard-details__content--description">
          {{ statsDescription }}
        </div>
      </div>
    </div>
    <div class="statscard-graph-icon" :class="iconColor">
      <vue-feather
        type="activity"
        class="statscard-graph-icon__activity"
        size="25"
      ></vue-feather>
      <vue-feather type="activity" size="25"></vue-feather>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsCard",
  props: {
    iconColor: {
      type: String,
      default: "",
    },
    statsNumber: {
      type: String,
      default: "",
    },
    statsDescription: {
      type: String,
      default: "",
    },
    cardIconClass: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "users",
    },
  },
};
</script>

<style lang="scss" scoped></style>
