export function getDashboardKey(data) {
    return `${data?.store_id}-${data?.timeframe_start}-${data?.timeframe_start}-${data?.is_live}-${data?.store_dashboard_types.join('-')}`
}

export function capitalizeText(text) {
  return text
    ?.toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const formatDateTime = (date) => { 
  const inputDate = new Date(date);

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true // Use 12-hour format
};

return inputDate.toLocaleDateString('en-US', options);
}
