<template>
  <div class="shared-dashboard__container">
    <div class="shared-dashboard__container--main">
      <div class="shared-dashboard__header">
        <div class="logo">
          <div class="logo-title">HOVASTORE</div>
        </div>
        <div class="sm:hidden">
          <div v-if="loadingStoreData" role="status" class="max-w-sm animate-pulse">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[280px] mb-4"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[180px] mb-2.5"></div>
            <span class="sr-only">Loading...</span>
          </div>
          <div class="store" v-else>
            <vue-feather type="shopping-bag" size="20" class="link-icon"></vue-feather>
            <div>
              <div class="store__name">{{ storeData?.store?.name }}</div>
              <div class="store__address">
                {{ storeData?.store?.location }}
              </div>
            </div>
          </div>
        </div>

        <button class="searchbar__right--theme" @click="toggle">
          <vue-feather :type="isDark ? 'loader' : 'moon'" class="searchbar__right--theme___icon" size="20" />
        </button>
      </div>
      <div class="shared-dashboard__header md:hidden">
        <div v-if="loadingStoreData" role="status" class="max-w-sm animate-pulse">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[280px] mb-4"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[180px] mb-2.5"></div>
          <span class="sr-only">Loading...</span>
        </div>
        <div class="store" v-else>
          <vue-feather type="shopping-bag" size="20" class="link-icon"></vue-feather>
          <div>
            <div class="store__name">{{ storeData?.store?.name }}</div>
            <div class="store__address">
              {{ storeData?.store?.location }}
            </div>
          </div>
        </div>
      </div>
      <div class="shared-dashboard__bottom">
        <div class="shared-dashboard__bottom--graph">
          <div class="shared-dashboard__bottom--graph-diagram">
            <Linechart :labels="graphLabels" :data="graphData" :start-date="startDate" :end-date="endDate"
              :dynamic-title="getDynamicTitle()" :is-dark="isDark" @update:start-date="handleUpdateStartDate"
              @update:date-range="handleFilterDate" @update:selected-interval="handleGroupingChange"
              @toggle:share-dashboard-modal="toggleShareDashboardModal" :intervals="intervals" :canShare="false"
              :dashboardTypes="dashboardTypes" :selectedDashboardType="selectedDashboardType">
              <select v-model="selectedType" v-if="dashboardTypes.length > 0"
                class="line-chart-select rounded-md md:mr-5 h-8 border-2 border-primary dark:border-[#0e7490] dark:bg-gray-800 dark:text-[#0e7490] capitalize"
                @change="onTypeChanged(selectedType)">
                <option v-for="type in dashboardTypes" :key="type" :value="type">
                  {{ type.split("_").join(" ") }}
                </option>
              </select>
            </Linechart>
          </div>
        </div>
      </div>
      <div>
        <updatestore v-if="showModal" @close-modal="handleModalClose" />
      </div>
      <div class="footer text-center">Powered by Hova AI &copy; {{ new Date().getFullYear() }}</div>
    </div>
  </div>
</template>

<script>
import { useToggle, useDark } from "@vueuse/core";
import { format } from "date-fns";
import { toast } from "vue3-toastify";
import Linechart from "@/components/dashboard/LineChart.vue";

export default {
  name: "DashboardView",
  components: {
    Linechart
  },

  setup() {
    const isDark = useDark();
    const toggleTheme = useToggle(isDark);
    const toggle = () => {
      useToggle(isDark);
      toggleTheme();
    };

    return {
      isDark,
      toggleTheme,
      toggle,
    };
  },

  data() {
    return {
      showModal: false,
      graphLabels: [],
      graphData: [],
      startDate: null,
      endDate: null,
      grouping: 'daily',
      dynamicTitle: "Transactions from 30 days ago to today",
      intervals: ['hourly', 'daily', 'monthly', 'yearly'],
      storeData: null,
      dashboardTypes: [],
      selectedDashboardType: '',
      selectedType: '',
    };
  },
  computed: {
    loadingStats() {
      return this.$store.getters.isLoadingStats;
    },
    loadingStoreData() {
      return this.$store.getters.isloadingsharedDashboardData;
    },
  },
  methods: {
    getDateRangeFormatted() {
      return {
        start: format(this.startDate, "dd/MM/yyyy HH:mm:ss"),
        end: format(this.endDate, "dd/MM/yyyy HH:mm:ss"),
      };
    },
    handleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    toggleShareDashboardModal() {
      this.$emit("toggle:share-dashboard-modal");
    },
    handleModalClose() {
      const modal = document.querySelector(".modal");
      modal.classList.add("closing");
      setTimeout(() => {
        modal.classList.remove("closing");
        this.showModal = false;
      }, 1000);
    },
    toggleProfileModal() {
      this.showProfileModal = !this.showProfileModal;
    },

    handleUpdateStartDate(newStartDate) {
      this.startDate = newStartDate;
    },
    handleUpdateEndDate(newEndDate) {
      this.endDate = newEndDate;
    },
    handleFilterDate(newDateRange) {
      if (!newDateRange) return;
      const { start, end } = newDateRange;
      this.startDate = start;
      this.endDate = end;
      this.getUpdatedData();

    },
    handleGroupingChange(newGrouping) {
      this.grouping = newGrouping;
      this.getUpdatedData();
    },
    onTypeChanged(type) {
      this.selectedType = type;
      this.getDynamicTitle()
      this.getUpdatedData();
    },
    getDynamicTitle() {
      if (this.startDate != null && this.endDate != null) {
        return `${this.$capitalizeText(this.selectedType)} from ${this.$formatDateTime(this.startDate)} to ${this.$formatDateTime(this.endDate)}`;
      } else {
        return `${this.$capitalizeText(this.selectedType)}`;
      }
    },

    getUpdatedData() {
      this.$store.dispatch("getSharedDashboardData", {
        token: this.$route.params.token,
        timeframe_start: this.startDate ? this.getDateRangeFormatted().start : null,
        timeframe_end: this.startDate ? this.getDateRangeFormatted().end : null,
        grouping: this.grouping,
        shared_dashboard_type: this.selectedType.toUpperCase(),
      }).then((res) => {
        this.graphData = res.values;
        this.graphLabels = res.labels;
      }).catch((error) => {
        toast.error(error.response?.data?.error?.message || "An error occurred while fetching data");
      });
    },
    getSharedStoreData() {
      this.$store.dispatch("getStoreSharedDashboardData", this.$route.params.token).then((res) => {
        this.storeData = res;
        this.dashboardTypes = res?.store_dashboard_types;
        this.selectedType = res?.store_dashboard_types[0];
        this.startDate = res?.timeframe_start ? new Date(res?.timeframe_start) : null;
        this.endDate = res?.timeframe_end ? new Date(res?.timeframe_end) : null;
        this.getUpdatedData();
        this.getDynamicTitle()
      }).catch((error) => {
        toast.error(error.response?.data?.error?.message || "An error occurred while fetching shared store data");
      });
    },
  },
  mounted() {
    // TODO: First load the dashboards that were shared with the user
    // /api/v1/store/shared/dashboard/{{dashboard_token}}
    // TODO: Update the initial title

    this.getSharedStoreData();
  }

};
</script>
