<template>
    <div class="h-[90%] sm:h-[60%] relative">
        <div class="flex justify-between sm:flex-col sm:mb-6">
            <div class="mb-5 text-primary dark:text-gray-200 font-bold">
                {{ dynamicTitle }}
            </div>
            <div class="flex justify-between">
                <slot></slot>
                <select v-model="selectedInterval"
                    class="line-chart-select rounded-md md:mr-5 h-8 border-2 border-primary dark:border-[#0e7490] dark:bg-gray-800 dark:text-[#0e7490] capitalize"
                    @change="onIntervalChanged(selectedInterval)">
                    <option v-for="interval in intervals" :key="interval">
                        {{ interval }}
                    </option>
                </select>
                <button
                    class="flex items-center justify-center rounded-md md:mr-5 w-8 h-8 border-2 border-primary dark:border-[#0e7490]"
                    @click="showDatePicker = !showDatePicker">
                    <vue-feather class="text-primary dark:text-[#0e7490]" type="filter" size="23" />
                </button>
                <button
                    class="flex items-center justify-center rounded-md w-8 h-8 border-2 border-primary dark:border-[#0e7490]"
                    @click="shareDashboard" v-if="canShare">
                    <vue-feather class="text-primary dark:text-[#0e7490]" type="share" size="23" />
                </button>
            </div>

        </div>

        <div v-if="showDatePicker" class="absolute top-0 right-24 z-10">
            <VDatePicker v-model.range="dateRange" :columns="2" mode="dateTime" isrange is24hr :time-accuracy="3"
                :is-dark="isDark" class="pb-10" />

            <div class="w-full px-3 pb-3 mt-4 absolute bottom-0">
                <button class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold w-full px-3 py-1 rounded-md"
                    @click="closeDatePicker">
                    Submit
                </button>
            </div>
        </div>
        <Line :options="chartOptions" :data="computedChartData" height="100%" class="z-0" />
    </div>
</template>

<script>
import { ref } from "vue";
import { Line } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    PointElement,
    LineElement,
    Filler,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    PointElement,
    LineElement,
    CategoryScale,
    Filler,
    LinearScale
);

import { VDatePicker } from "v-calendar";

export default {
    components: { Line, VDatePicker },
    props: {
        labels: {
            type: Array,
            default: null,
        },
        data: {
            type: Array,
            default: null,
        },
        startDate: Date,
        endDate: Date,
        dynamicTitle: String,
        isDark: Boolean,
        intervals: Array,
        canShare: {
            type: Boolean,
            default: true,
        },
        dashboardTypes: {
            type: Array,
            default: () => [],
        },
        selectedDashboardType: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const showDatePicker = ref(false);
        const dateRange = ref({
            start: new Date(new Date() - new Date(30 * 24 * 60 * 60 * 1000)),
            end: new Date(),
        });

        const selectedInterval = ref("daily");
        const selectedType = props.dashboardTypes.length > 0 ? ref(props.dashboardTypes[0]) : ref("");

        return {
            showDatePicker,
            dateRange,
            selectedInterval,
            selectedType
        };
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: "My Chart Title",
                    fontSize: 18,
                    fontColor: "#333",
                },
                scales: {
                    x: {
                        ticks: {
                            color: "#888",
                        },
                    },
                    y: {
                        ticks: {
                            color: "#888",
                        },
                    },
                },
                date: null,
            },
        };
    },
    computed: {
        computedChartData() {
            const borderColor = this.isDark ? "#0e7490" : "#581845";
            const pointBackgroundColor = this.isDark ? "#0e7490" : "#581845";
            const backgroundColor = this.isDark ? "#0e7490" : "#581845";
            return {
                labels: this.labels,
                datasets: [
                    {
                        label: `Transactions`,
                        fill: false,
                        borderColor: borderColor,
                        borderWidth: 2,
                        pointRadius: 3,
                        pointBackgroundColor: pointBackgroundColor,
                        backgroundColor: backgroundColor,
                        tension: 0.5,
                        data: this.data,
                    },
                ],
            };
        },
    },
    methods: {
        closeDatePicker() {
            this.showDatePicker = false;
            this.$emit("update:date-range", this.dateRange);
        },
        onIntervalChanged(newInterval) {
            this.$emit("update:selected-interval", newInterval);
        },
        shareDashboard() {
            this.$emit("toggle:share-dashboard-modal");
        },
    },
};
</script>