<template>
  <div>
    <div class="">
      <div class="h-screen fixed w-full z-20 pr-8 bg-white dark:bg-gray-900 hidden sm:flex" v-if="showSidebar">
        <side-bar @close="handleSidebar" @toggle-profilemodal="toggleProfileModal" />
      </div>
      <Searchbar @clicked="toggle" :type="isDark ? 'loader' : 'moon'" @handleSidebar="handleSidebar" :showSearch="false"
        @handleMyStores="handleMyStores()" :showStoreBtn="true" />

      <div class="dashboard-statscards">
        <div v-for="item in statsCardItems" :key="item.iconType" class="flex">
          <StatsSkeleton v-if="loadingStats" />
          <Statscard v-else :iconType="item.iconType" :iconColor="item.iconColor" :statsNumber="item.statsNumber"
            :statsDescription="item.statsDescription" :cardIconClass="item.cardIconClass" />
        </div>
      </div>
      <div class="dashboard-bottom">
        <div class="dashboard-bottom__graph">
          <div class="dashboard-bottom__graph--diagram">
            <Linechart :labels="graphLabels" :data="graphData" :start-date="startDate" :end-date="endDate"
              :dynamic-title="dynamicTitle" :is-dark="isDark" @update:grouping="handleGroupingChange"
              @update:date-range="handleFilterDate" />
          </div>
        </div>
        <div class="dashboard-bottom__pie">
          <SystemErrors />
        </div>
      </div>
    </div>
    <div>
      <updatestore v-if="showModal" @close-modal="handleModalClose" />
    </div>
  </div>
</template>

<script>
import { useToggle, useDark } from "@vueuse/core";
import StatsSkeleton from "@/skeletons/StatsSkeleton";
import { format, isValid } from "date-fns";
import { toast } from "vue3-toastify";

export default {
  name: "DashboardView",
  components: {
    StatsSkeleton,
  },
  setup() {
    const isDark = useDark();
    const toggleTheme = useToggle(isDark);
    const toggle = () => {
      useToggle(isDark);
      toggleTheme();
    };

    return {
      isDark,
      toggleTheme,
      toggle,
    };
  },

  data() {
    return {
      statsCardItems: [
        {
          key: 'users',
          iconType: "users",
          iconColor: "text-emerald-500",
          statsNumber: "30",
          statsDescription: "Total Users",
          cardIconClass: "bg-cyan-700 bg-opacity-10",
        },
        {
          key: 'stores',
          iconType: "shopping-bag",
          iconColor: "text-sky-600",
          statsNumber: "60",
          statsDescription: "Total Stores",
          cardIconClass: "bg-sky-600 bg-opacity-10",
        },
        {
          key: 'transactions',
          iconType: "gift",
          iconColor: "text-red-400",
          statsNumber: "600",
          statsDescription: "Total Transactions",
          cardIconClass: "bg-red-400 bg-opacity-10",
        },
        {
          key: 'products',
          iconType: "life-buoy",
          iconColor: "text-primary",
          statsNumber: "100",
          statsDescription: "Total Products",
          cardIconClass: "bg-primary bg-opacity-10",
        },
      ],
      showSidebar: false,
      showModal: false,
      graphLabels: [],
      graphData: [],
      startDate: new Date(new Date() - new Date(30 * 24 * 60 * 60 * 1000)),
      endDate: new Date(),
      dynamicTitle: "Transactions from 30 days ago to today",
      stores: [],
    };
  },
  computed: {
    loadingStats() {
      return this.$store.getters.isLoadingStats;
    },
  },
  methods: {
    handleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    handleModalClose() {
      const modal = document.querySelector(".modal");
      modal.classList.add("closing");
      setTimeout(() => {
        modal.classList.remove("closing");
        this.showModal = false;
      }, 1000);
    },
    toggleProfileModal() {
      this.showProfileModal = !this.showProfileModal;
    },

    handleUpdateStartDate(newStartDate) {
      this.startDate = newStartDate;

    },
    handleUpdateEndDate(newEndDate) {
      this.endDate = newEndDate;

    },

    handleFilterDate(newDateRange) {
      if (!newDateRange) return;

      let startDate = "";
      let endDate = "";

      if (isValid(newDateRange.start) && isValid(newDateRange.end)) {
        startDate = format(newDateRange.start, "dd/MM/yyyy HH:mm:ss");
        endDate = format(newDateRange.end, "dd/MM/yyyy HH:mm:ss");
        this.dynamicTitle = `Transactions from ${format(newDateRange.start, 'MMMM do, yyyy h:mm a')} to ${format(newDateRange.end, 'MMMM do, yyyy h:mm a')}`;
        this.$emit("update:dynamic-title", this.dynamicTitle);
      } else {
        toast.error("Invalid date values");
      }
      this.$store
        .dispatch("getStoreTransactionsCountDashboardData", {
          start_time: startDate,
          end_time: endDate,
          grouping: this.grouping,
        })
        .then((res) => {
          this.graphData = res.values;
          this.graphLabels = res.labels;
        });
    },
    handleGroupingChange(newGrouping) {
      this.grouping = newGrouping;
      this.handleFilterDate({
        start: this.startDate,
        end: this.endDate,
      });
    },
    handleMyStores() {
      this.$router.push("/store/dashboard");
    },
  },
  async mounted() {
    try {
      const storesData = await this.$store.dispatch("getUserStores");
      this.stores = storesData;
      if (storesData?.length > 0) {
        this.$store.commit("SET_SELECTED_STORE", storesData[0]);
      }
    } catch (error) {
      toast.error("Error fetching stores");
    }

    this.$store.dispatch("getDashboardStats").then((res) => {
      this.statsCardItems[0].statsNumber = res.total_users;
      this.statsCardItems[1].statsNumber = res.total_stores;
      this.statsCardItems[2].statsNumber = res.total_transactions;
      this.statsCardItems[3].statsNumber = res.total_products;
    });
    this.$store.dispatch("getDashboardStats").then((res) => {
      this.statsCardItems.find(item => item.key === 'users').statsNumber = res.total_users;
      this.statsCardItems.find(item => item.key === 'stores').statsNumber = res.total_stores;
      this.statsCardItems.find(item => item.key === 'transactions').statsNumber = res.total_transactions;
      this.statsCardItems.find(item => item.key === 'products').statsNumber = res.total_products;
    });

  },
};
</script>
