export default {
  request_sms: "/auth/request_sms_verification",
  verify_code: "/auth/sms_code_authentication",
  verify_admin: "/admin/user/permissions",
  authenticated_user: "/auth",
  logout: "/logout",
  platform_stats: "/admin/dashboard/platform/stats",
  // graph_stats: "/admin/stats/transactions/monthly",
  store_stats: "/store/stats",
  // store_transactions: "/store/dashboard/data/transactions/count",
  store_transactions: "/store/chart/data",
  store_dashboard_types: "/store/dashboard/types",
  dashboard_share_link: "/store/dashboard/share",
  shared_dashboard_data: "/store/shared/dashboard/data",
  graph_stats:
    "/store/stats/transactions/18/monthly?start_time=23/10/2019%2012:30:12&end_time=28/11/2023%2017:22:10",
  get_user_stores: "/store/me",
  get_store_shared_data: "/store/shared/dashboard",
  charts_data: "/store/chart/data",
};
