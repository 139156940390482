<template>
  <div class="storecard" :class="storeClass">
    <div class="storecard__amount" :class="amountColor">
      {{ amount }}
    </div>
    <div class="storecard__content">
      <div class="storecard__content--description" :class="amountColor">
        {{ details }}
      </div>
      <div class="storecard__content--insights">
         <div class="storecard__content--insights__percentage--value">
            {{ percentage >= 0 ? `+${percentage}%`:`${percentage}%` }}
          </div>
          <vue-feather
            :type="percentage >= 0 ? 'arrow-up' : 'arrow-down'"
            size="20"
            class="storecard__content--insights__percentage--icon"
          ></vue-feather>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreCard",
  props: {
    details: {
      type: String,
      default: "",
    },
    amount: {
      type: String,
      default: "",
    },
    percentage: {
      type: Number,
      default: 0,
    },
    storeClass: { type: String, default: "" },
    amountColor: { type: String, default: "" },
    detailsColor: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped></style>
