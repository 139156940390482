<template>
  <div class="searchbar-container">
    <div class="searchbar__left">
      <div class="searchbar__left--menu__icon">
        <vue-feather @click="handleSidebar" type="align-left" size="25" />
      </div>

      <select v-if="showSearch" class="searchbar__left--input" @change="handleStoreSelected">
        <option value="null" disabled>Select a store</option>
        <option v-for="store in stores" :key="store.id" :value="store.id"
          :selected="selectedStore && store.id == selectedStore.id">
          {{ store.name }}
        </option>
      </select>

      <button @click="handleMyStores" v-if="showStoreBtn" class="border border-gray-600 py-[5px] px-3 rounded-md">My Stores</button>
    </div>

    <div class="searchbar__right">
      <button class="searchbar__right--theme" @click="clicked">
        <vue-feather :type="type" class="searchbar__right--theme___icon" size="20" />
      </button>

      <div class="searchbar__right--notification">
        <div>
          <vue-feather type="bell" size="20" class="searchbar__right--notification___bellIcon"></vue-feather>
        </div>
        <div class="searchbar__right--notification___alert"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    type: {
      type: String,
      required: true,
    },
    stores: Array,
    showSearch: {
      type: Boolean,
      default: true,
    },
    showStoreBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    storeName() {
      return this.$store.getters.getSelectedStore?.name;
    },
    selectedStore() {
      return this.$store.getters.getSelectedStore;
    },
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
    handleMyStores() {
      this.$emit("handleMyStores");
    },
    handleSidebar() {
      this.$emit("handleSidebar");
    },
    handleStoreSelected(event) {
      const selectedStoreId = parseInt(event.target.value);

      const selectedStore = this.stores.find(
        (store) => store.id === selectedStoreId
      );

      // Make sure the selected store is not null and emit the event after setting the selected store
      if (selectedStore) {
        this.$store.commit('SET_SELECTED_STORE', selectedStore);
        this.$emit("store-changed");

      }
    }
  }
}

</script>
