import http from "@/helper/http";
import api from "@/helper/api";
import { getDashboardKey } from "@/helper/utils";

export default {
  state: {
    selectedStore: null,
    loadingStats: false,
    transactionStats: [],
    stats: [],
    loadingStoreStats: false,
    storeStats: {},
    loadingStoreTransactionCountData: false,
    storeTransactionCountData: {},
    storeDashboardTypes: [],
    loadingStoreDashboardTypes: false,
    dashboardShareLink: null,
    loadingDashboardShareLink: false,
    sharedDashboardData: {},
    loadingSharedDashboardData: false,
    linkCache: {},
    sharedDashboardStoreData: {},
    loadingSharedDashboardStoreData: false,
    loadingChartsData: false,
    chartsData: {},
  },
  mutations: {
    SET_LOADING_STATS(state, loadingStats) {
      state.loadingStats = loadingStats;
    },
    SET_STATS(state, stats) {
      state.stats = stats;
    },
    SET_LOADING_STORE_STATS(state, loadingStoreStats) {
      state.loadingStoreStats = loadingStoreStats;
    },
    SET_STORE_STATS(state, storeStats) {
      state.storeStats = storeStats;
    },
    SET_TRANSACTION_STATS(state, transactionStats) {
      state.transactionStats = transactionStats;
    },
    SET_LOADING_STORE_TRANSACTION_COUNT_DATA(
      state,
      loadingStoreTransactionCountData
    ) {
      state.loadingStoreTransactionCountData = loadingStoreTransactionCountData;
    },
    SET_STORE_TRANSACTION_COUNT_DATA(state, storeTransactionCountData) {
      state.storeTransactionCountData = storeTransactionCountData;
    },
    SET_STORE_DASHBOARD_TYPES(state, storeDashboardTypes) {
      state.storeDashboardTypes = storeDashboardTypes;
    },
    SET_LOADING_STORE_DASHBOARD_TYPES(state, loadingStoreDashboardTypes) {
      state.loadingStoreDashboardTypes = loadingStoreDashboardTypes;
    },
    SET_STORE_DASHBOARD_SHARE_LINK(state, { response_data, linkUniqueKey }) {
      state.dashboardShareLink =
        window.location.origin +
        "/store/dashboard/shared/" +
        response_data?.token;
      state.linkCache[linkUniqueKey] = state.dashboardShareLink;
    },
    SET_LOADING_DASHBOARD_SHARE_LINK(state, loadingDashboardShareLink) {
      state.loadingDashboardShareLink = loadingDashboardShareLink;
    },
    SET_SHARED_DASHBOARD_DATA(state, sharedDashboardData) {
      state.sharedDashboardData = sharedDashboardData;
    },
    SET_LOADING_SHARED_DASHBOARD_DATA(state, loadingSharedDashboardData) {
      state.loadingSharedDashboardData = loadingSharedDashboardData;
    },
    SET_SELECTED_STORE(state, selectedStore) {
      state.selectedStore = selectedStore;
    },
    SET_SHARED_DASHBOARD_STORE_DATA(state, sharedDashboardStoreData) {
      state.sharedDashboardStoreData = sharedDashboardStoreData;
    },
    SET_LOADING_SHARED_DASHBOARD_STORE_DATA(
      state,
      loadingSharedDashboardStoreData
    ) {
      state.loadingSharedDashboardStoreData = loadingSharedDashboardStoreData;
    },
    SET_LOADING_CHARTS_DATA(state, loadingChartsData) {
      state.loadingChartsData = loadingChartsData;
    },
    SET_CHARTS_DATA(state, chartsData) {
      state.chartsData = chartsData;
    },
  },
  actions: {
    async getTransactionStats({ commit }, { start_time, end_time }) {
      commit("SET_LOADING_STATS", true);
      try {
        const url = `${api.platform_stats}?start_time=${start_time}&end_time=${end_time}`;
        const response = await http.get(url);
        commit("SET_TRANSACTION_STATS", response?.data);
        commit("SET_LOADING_STATS", false);
        return response?.data;
      } catch (error) {
        commit("SET_LOADING_STATS", false);
        throw error;
      }
    },
    getDashboardStats({ commit }) {
      commit("SET_LOADING_STATS", true);
      return new Promise((resolve, reject) => {
        http
          .get(api.platform_stats)
          .then((response) => {
            commit("SET_STATS", response?.data);
            commit("SET_LOADING_STATS", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_STATS", false);
            reject(error);
          });
      });
    },
    getStoreDashboardTypes({ commit }) {
      commit("SET_LOADING_STORE_DASHBOARD_TYPES", true);
      return new Promise((resolve, reject) => {
        http
          .get(api.store_dashboard_types)
          .then((response) => {
            commit("SET_STORE_DASHBOARD_TYPES", response?.data);
            commit("SET_LOADING_STORE_DASHBOARD_TYPES", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_STORE_DASHBOARD_TYPES", false);
            reject(error);
          });
      });
    },

    createDashboardShareLink({ commit }, data) {
      commit("SET_LOADING_DASHBOARD_SHARE_LINK", true);
      return new Promise((resolve, reject) => {
        http
          .post(api.dashboard_share_link, data)
          .then((response) => {
            commit("SET_STORE_DASHBOARD_SHARE_LINK", {
              response_data: response?.data,
              linkUniqueKey: getDashboardKey(data),
            });
            commit("SET_LOADING_DASHBOARD_SHARE_LINK", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_DASHBOARD_SHARE_LINK", false);
            reject(error);
          });
      });
    },
    getStoreStats({ commit }, data) {
      commit("SET_LOADING_STORE_STATS", true);
      return new Promise((resolve, reject) => {
        const url = `${api.store_stats}/${data.selectedStore}?start_time=${data.start_time}&end_time=${data.end_time}`;
        http
          .get(url)
          .then((response) => {
            commit("SET_STORE_STATS", response?.data);
            commit("SET_LOADING_STORE_STATS", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_STORE_STATS", false);
            reject(error);
          });
      });
    },
    getStoreTransactionStats({ commit }, data) {
      commit("SET_LOADING_STORE_TRANSACTION_COUNT_DATA", true);

      const url = `${api.store_transactions}/${data.selectedStore}/${data.dashboard_type}/${data.interval}?start_time=${data.start_time}&end_time=${data.end_time}`;
      return new Promise((resolve, reject) => {
        http
          .get(url)
          .then((response) => {
            commit("SET_STORE_TRANSACTION_COUNT_DATA", response?.data);
            commit("SET_LOADING_STORE_TRANSACTION_COUNT_DATA", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_STORE_TRANSACTION_COUNT_DATA", false);
            reject(error);
          });
      });
    },
    getSharedDashboardData(
      { commit },
      { token, timeframe_start, timeframe_end, shared_dashboard_type, grouping }
    ) {
      commit("SET_LOADING_SHARED_DASHBOARD_DATA", true);
      return new Promise((resolve, reject) => {
        const args =
          timeframe_start && timeframe_end
            ? `?start_time=${timeframe_start}&end_time=${timeframe_end}`
            : "";
        http
          .get(
            `${api.shared_dashboard_data}/${token}/${shared_dashboard_type}/${grouping}${args}`
          )
          .then((response) => {
            commit("SET_SHARED_DASHBOARD_DATA", response?.data);
            commit("SET_LOADING_SHARED_DASHBOARD_DATA", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_SHARED_DASHBOARD_DATA", false);
            reject(error);
          });
      });
    },
    getStoreSharedDashboardData({ commit }, token) {
      commit("SET_LOADING_SHARED_DASHBOARD_STORE_DATA", true);
      return new Promise((resolve, reject) => {
        http
          .get(`${api.get_store_shared_data}/${token}`)
          .then((response) => {
            commit("SET_SHARED_DASHBOARD_STORE_DATA", response?.data);
            commit("SET_LOADING_SHARED_DASHBOARD_STORE_DATA", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_SHARED_DASHBOARD_STORE_DATA", false);
            reject(error);
          });
      });
    },
    getChartsData(
      { commit },
      { storeId, start_time, end_time, dashboard_type, time_interval }
    ) {
      commit("SET_LOADING_CHARTS_DATA", true);
      return new Promise((resolve, reject) => {
        http
          .get(
            `${api.charts_data}/${storeId}/${dashboard_type}/${time_interval}?start_time=${start_time}&end_time=${end_time}`
          )
          .then((response) => {
            commit("SET_CHARTS_DATA", response?.data);
            commit("SET_LOADING_CHARTS_DATA", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_CHARTS_DATA", false);
            reject(error);
          });
      });
    },
  },
  getters: {
    isLoadingStats: (state) => state.loadingStats,
    isLoadingStoreStats: (state) => state.loadingStoreStats,
    isLoadingStoreTransactionCountData: (state) =>
      state.loadingStoreTransactionCountData,
    isloadingsharedDashboardData: (state) => state.loadingSharedDashboardData,
    getStoreDashboardTypes: (state) => state.storeDashboardTypes,
    getDashboardShareLink: (state) => state.dashboardShareLink,
    getLinkCache: (state) => state.linkCache,
    getSelectedStore: (state) => state.selectedStore,
    isLoadingStoreDashboardTypes: (state) => state.loadingStoreDashboardTypes,
  },
};
