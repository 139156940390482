<template>
  <div class="system-error__container">
    <div class="system-error__title">Server Status in the past hour</div>
    <div class="system-error__graph">
      <Pie :data="data" :options="options" />
    </div>
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: "DashboardStoreCards",
  components: {
    Pie,
  },
  methods: {},
  data() {
    return {
      data: {
        labels: ["200", "400", "401", "404", "500"],
        datasets: [
          {
            backgroundColor: [
              "rgba(65, 184, 131, 0.5)",
              "rgba(228, 102, 81, 0.5)",
              "rgba(0, 216, 255, 0.5)",
              "rgba(221, 27, 22, 0.5)",
              "rgba(255, 87, 51, 0.5)",
            ],
            data: [40, 20, 80, 10, 5],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
