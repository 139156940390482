<template>
    <div role="status" class="animate-pulse md:w-[50%] w-[90%] mx-auto">
        <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
        <div class="w-full h-3 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
        <div class="w-full h-10 mb-7 bg-gray-200 rounded-md dark:bg-gray-700"></div>
        <div class="w-full h-10 mb-8 bg-gray-200 rounded-md dark:bg-gray-700"></div>
    </div>
</template>

<script>
export default {
    setup() {
        return {}
    }
}
</script>

<style lang="scss" scoped></style>